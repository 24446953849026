<template>
  <div class="container py-5">
   

    <div v-if="loadingGeral" class="p-3">Carregando...</div>
    <div v-else>
       <h2>Escolha o plano perfeito para seu anúncio</h2>
      <div class="row justify-content-center mt-5">
        <div
          class="col-12 col-md-6 col-lg-5 mb-3"
          v-for="(plano, index) in planos"
          :key="'plano_' + index"
        >
          <div class="card plano border-0 shadow">
            <div class="card-body">
              <div class="ribbon-wrapper" v-if="plano.recomendado">
              <div class="ribbon">+ Popular</div>
            </div>

              <h5 class="text-info font-weight-bold mb-4">{{ plano.nome }}</h5>
              <div class="preco-plano" v-if="plano.valor">
                <h3 class="mb-0">
                  <span>{{ plano.valor | formatMoney }}</span>
                </h3>
                <small>por anúncio</small>
              </div>
              <h1 class="text-success" v-else>Grátis</h1>

               <ul class="text-left topicos-plano mt-3">
                <li
                  v-for="(item, index) in planoAnuncioCore.itensDescricaoPlano(
                    plano.descricao
                  )"
                  :key="'p' + index"
                >
                  {{ item }}
                </li>
              </ul>

              <router-link
               v-if="plano.valor"
        :to="`/dashboard/checkout-plano/${idAnuncio}/${config.PLANO_DESTAQUE_ID}`"
        class="btn btn-primary btn-block"
        >Escolher plano</router-link
      >

              <button
                v-else
                class="btn btn-primary btn-block"
                :disabled="desabilitarBotoes"
                @click="escolherPlano(config.PLANO_ANUNCIO_PREMIUM)"
              >
                <span v-if="loadingPlano[config.PLANO_ANUNCIO_PREMIUM]"
                  >Escolhendo...</span
                >
                <span v-else>Escolher plano</span>
              </button>
            </div>
          </div>
        </div>
        </div>
       

      <div class="mt-3">
        <i class="fa fa-check mr-3 text-success"></i>
        <h5 class="d-inline-block">
          Aqui seu anúncio é visto por mais de <b>200.000</b> ciclitas.
        </h5>
      </div>

    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import config from "../../../config";
import captureError from "../../../helpers/captureError";
import planoAnuncioCore from "../../../core/planoAnuncio";

export default {
  data() {
    return {
      config,
      planoAnuncioCore,

      loadingGeral: false,
      idAnuncio: undefined,
      loadingPlano: {},
      desabilitarBotoes: false,

      planos: [],
    };
  },
  created() {
    this.idAnuncio = this.$route.params.idAnuncio;

    if (!this.idAnuncio) {
      this.$router.replace("/dashboard/meus-anuncios");
    }

    this.popularPlanos();
  },
  methods: {
    async popularPlanos() {
      try {
        this.loadingGeral = true;

        var response = await axios.get(`${config.API_URL}/planoAnuncio`);

        this.planos = response.data;

        this.loadingGeral = false;
      } catch (e) {
        this.loadingGeral = false;
        captureError(e);

        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível carregar os planos. Tente novamente mais tarde ou contate o suporte.",
        });

        this.$router.replace("/dashboard/meus-anuncios");
      }
    },
    async escolherPlano(idPlanoEscolhido) {
      try {
        this.$set(this.loadingPlano, idPlanoEscolhido, true);
        this.desabilitarBotoes = true;

        await axios.post(
          `${config.API_URL}/anuncio/vincularPlano?idAnuncio=${this.idAnuncio}&idPlanoAnuncio=${idPlanoEscolhido}`,
          {}
        );

        this.$set(this.loadingPlano, idPlanoEscolhido, false);
        this.desabilitarBotoes = false;

        await Swal.fire({
          icon: "success",
          title: "Plano ativado com sucesso.",
        });

        this.$router.replace("/dashboard/meus-anuncios");
      } catch (e) {
        captureError(e);

        this.$set(this.loadingPlano, idPlanoEscolhido, false);
        this.desabilitarBotoes = false;

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível escolher o plano. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../styles/variaveis";

.topicos-plano {
  min-height: 120px;
}

.plano {
  border-radius: @border-radius;
}

.preco-cortado {
  text-decoration: line-through;
}

.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  left: -3px;

  .ribbon {
    font: bold 15px sans-serif;
    color: #333;
    text-align: center;
    transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    top: 15px;
    left: -30px;
    width: 120px;
    background-color: #ebb134;
    color: #fff;
  }
}
</style>